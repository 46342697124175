<template>
	<section v-if="false !== race && false !== status" class="min-h-full">
		<header class="md:flex md:items-center md:justify-between ">
			<div class="sm:flex items-center sm:space-x-5">
				<div class="-mb-1">
					<div class="sm:flex items-center mb-1">
						<h1 class="text-4xl font-bold text-mono-lightest mb-2" v-html="race.name"></h1>
					</div>

					<div class="flex items-center mt-1" v-if="null !== race.starts_at">
						<CalendarIcon class="flex-shrink-0 mr-2 h-4 w-4 sm:h-6 sm:w-6 text-mono-mid" /> 

						<div class="flex items-center">
							<time class="text-sm sm:text-md font-light text-mono-mid">{{ $functions.formatDate( race.starts_at ) }}</time> <span class="px-2">&mdash;</span> <time class="text-sm sm:text-md font-light text-mono-mid">{{ $functions.formatDate( race.ends_at ) }}</time>
						</div>
					</div>
				</div>
			</div>
		</header>

		<section class="py-12">
			<div class="sm:hidden">
				<label for="tabs" class="sr-only">Select a tab</label>
				<select @change="select_tab( $event )" id="tabs" name="tabs" class="text-mono-darkest block w-full focus:ring-advntm-accent focus:border-border-mono-darkest border-mono-darker">
					<option v-show="tab_is_enabled( tab )" :value="tab.id" v-for="tab in tabs" :key="tab.name" :selected="active_tab === tab.id">{{ tab.name }}</option>
				</select>
			</div>
			<div class="hidden sm:block">
				<nav class="flex space-x-4" aria-label="Tabs">
					<button @click.prevent="select_tab_via_button( tab )" v-show="tab_is_enabled( tab )" v-for="tab in tabs" :key="tab.name" :class="[active_tab === tab.id ? 'bg-mono-darker text-mono-lightest' : 'text-mono-dark hover:text-advntm-highlight', 'p-3 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined" class="h-auto">
						{{ tab.name }}
					</button>
				</nav>
			</div>
		</section>

		<section v-if="active_tab === 'information'" class="">
			<div class="pb-7">
				<h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-mono-lighter">Race information</h2>
			</div>
			<div class="border-t border-mono-darker pt-7">
				<dl class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 items-center">
					<div v-if="null !== race.location && typeof race.location !== 'undefined'" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Location
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<LocationMarkerIcon class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />
							<span class="leading-normal">{{ race.location }}</span>
						</dd>
					</div>

					<div v-if="null !== race.country && typeof race.country !== 'undefined'" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Country
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<FlagIcon class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />
							<span class="leading-normal">{{ race.country }}</span>
						</dd>
					</div>
					<div v-if="null !== race.distance_miles && typeof race.distance_miles !== 'undefined'" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Distance
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<MapIcon class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />
							<span class="leading-normal">{{ race.distance_miles }} miles</span>
						</dd>
					</div>
					<div v-if="null !== race.temperature && typeof race.temperature !== 'undefined'" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Temperature
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<SunIcon class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />
							<span class="leading-normal">{{ race.temperature }}&deg;</span>
						</dd>
					</div>
					<div v-if="null !== race.terrain && typeof race.terrain !== 'undefined'" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Terrain
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<GlobeIcon class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />
							<span class="leading-normal">{{ race.terrain }}</span>
						</dd>
					</div>
					<div v-if="false !== series" class="race-detail">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Series
						</dt>
						<dd class="mt-1 text-md text-mono-lightest flex items-center">
							<FolderOpenIcon class="flex-shrink-0 mr-2 h-6 w-6" aria-hidden="true" />
							<span class="leading-normal">{{ series.name }}</span>
						</dd>
					</div>
					<div v-if="null !== race.description && typeof race.description !== 'undefined'" class="col-span-full mt-6">
						<dt class="text-sm font-medium text-mono-dark mb-2">
							Description
						</dt>
						<dd class="mt-1 text-sm text-mono-lighter">
							<p class="text-sm font-light text-mono-light mb-0" v-html="race.description"></p>
						</dd>
					</div>
				</dl>
			</div>
		</section>

		<section v-if="active_tab === 'waiting_list'" class="">
			<div class="pb-7">
				<h2 id="to_do_list-title" class="text-lg leading-6 font-medium text-mono-lighter">Waiting List</h2>
			</div>

			<div>
				<div class="flex items-center border-t border-b border-mono-darker py-7 mb-7">
					<ExclamationIcon class="mt-0.25 w-8 h-8 text-state-danger mr-3" />

					<p>You are in the waiting list. When a place becomes available, you will be contacted via <a :href="'mailto:' + $store.getters.user.email" v-html="$store.getters.user.email"></a>.</p>
				</div>

				<div class="waiting-list-position">
					<h3 class="text-lg leading-6 font-medium text-mono-lighter">Your position</h3>

					<table class="mt-7" v-if="Object.values( waiting_list ).length > 0">
						<thead class="border-b border-mono-darker">
							<tr>
								<th class="text-mono-dark font-medium text-sm pb-4">Position</th>
								<th class="text-mono-dark font-medium text-sm pb-4">Name</th>
								<th class="text-mono-dark font-medium text-sm pb-4">Nationality</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="competitor in waiting_list" :key="competitor.id">
								<td v-html="competitor.position"></td>
								<td v-if="competitor.id === $store.getters.user.id" v-html="competitor.first_name + ' ' + competitor.last_name"></td>
								<td v-else><div class="redacted"></div></td>
								<td v-if="competitor.id === $store.getters.user.id" ><img class="w-10" :src="'https://admin.advntm.com/flags/' + competitor.nationality + '.svg'" role="presentation"></td>
								<td v-else><div class="redacted"></div></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</section>

		<section v-if="active_tab === 'to_do_list'" class="">
			<div class="pb-7">
				<h2 id="to_do_list-title" class="text-lg leading-6 font-medium text-mono-lighter">To do list</h2>
			</div>

			<Loader v-if="true === get_tab( 'to_do_list' ).loading" text="Loading your to do list" /> 

			<div v-if="typeof journey.steps !== 'undefined' && Object.values( journey ).length > 0">
				<Checklist class="border-t border-b border-mono-darker" v-on:input_checked="input_checked" :items="journey.steps" attr="step" />
			</div>

			<div v-else>
				<p v-if="true !== get_tab( 'to_do_list' ).loading" v-html="get_tab( 'to_do_list' ).emptyLabel"></p>
			</div>
		</section>

		<section v-if="active_tab === 'payments'" class="">
			<div class="pb-7">
				<h2 id="payments-title" class="text-lg leading-6 font-medium text-mono-lighter">Payments</h2>
			</div>

			<Loader v-if="true === get_tab( 'payments' ).loading" text="Loading your payment schedule" /> 

			<div v-if="Object.values( schedule ).length > 0">
				<div class="border-t border-mono-darker py-9">
					<Schedule :schedule="schedule" />
				</div>
			</div>

			<div v-else>
				<p v-if="true !== get_tab( 'payments' ).loading" v-html="get_tab( 'payments' ).emptyLabel"></p>
			</div>
		</section>

		<section v-if="active_tab === 'fundraising'" class="">
			<div class="pb-7">
				<h2 id="fundraising-title" class="text-lg leading-6 font-medium text-mono-lighter">Fundraising</h2>
			</div>

			<Loader v-if="true === get_tab( 'fundraising' ).loading" text="Loading fundraising information" /> 

			<div v-if="Object.values(fundraising).length > 0">
				<div class="border-t border-mono-darker py-9">
					<div v-if="typeof fundraising.fundraising_platform">
						<img class="fundraising-platform" :src="require('@/assets/images/png/' + fundraising.fundraising_platform + '.png')" role="presentation">
					</div>

					<dl>
						<dd class="mt-1 text-sm text-mono-lighter">
							<ul role="list" class="border border-mono-darker rounded-md divide-y divide-mono-darker">
								<li v-if="typeof fundraising.fundraising_page_login_url !== 'undefined'" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
									<div class="w-0 flex-1 flex items-center">
										<PaperClipIcon class="flex-shrink-0 h-5 w-5 text-mono-dark" aria-hidden="true" />
										<span class="ml-2 flex-1 w-0 truncate text-lg font-medium">Login to the fundraising page</span>
									</div>

									<div class="ml-4 flex-shrink-0">
										<div>
											<a target="_blank" :href="fundraising.fundraising_page_login_url" class="font-medium text-advntm-accent hover:text-advntm-highlight">
												<span>Login</span>
											</a>
										</div>
									</div>
								</li>

								<li v-if="typeof fundraising.fundraising_page_setup_url !== 'undefined'" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
									<div class="w-0 flex-1 flex items-center">
										<PaperClipIcon class="flex-shrink-0 h-5 w-5 text-mono-dark" aria-hidden="true" />
										<span class="ml-2 flex-1 w-0 truncate text-lg font-medium">Set up or manage a Fundraising page</span>
									</div>

									<div class="ml-4 flex-shrink-0">
										<div>
											<a target="_blank" :href="fundraising.fundraising_page_setup_url" class="font-medium text-advntm-accent hover:text-advntm-highlight">
												<span>Manage</span>
											</a>
										</div>
									</div>
								</li>
							</ul>
						</dd>
					</dl>
				</div>
			</div>

			<div v-else>
				<p v-if="true !== get_tab( 'fundraising' ).loading" v-html="get_tab( 'fundraising' ).emptyLabel"></p>
			</div>
		</section>

		<section v-if="active_tab === 'race_pack'" class="">
			<div class="pb-7">
				<h2 id="race_pack-title" class="text-lg leading-6 font-medium text-mono-lighter">Downloads</h2>
			</div>

			<Loader v-if="true === get_tab( 'race_pack' ).loading" text="Loading your downloads" /> 

			<div v-if="Object.values( packs ).length > 0" class="border-t border-b border-mono-darker">
				<dl v-if="Object.values( packs ).length > 0" class="mt-9 pb-9">
					<dd class="mt-1 text-sm text-mono-lighter">
						<ul role="list" class="border border-mono-darker rounded-md divide-y divide-mono-darker">
							<li v-for="pack in packs" :key="pack.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
								<div class="w-0 flex-1 flex items-center">
									<PaperClipIcon class="flex-shrink-0 h-5 w-5 text-mono-dark" aria-hidden="true" />
									<span class="ml-2 flex-1 w-0 truncate text-lg font-medium">{{ pack.description }}</span>
								</div>

								<div class="ml-4 flex-shrink-0">
									<div v-if="$functions.isMobile()">
										<a target="_blank" :href="pack.asset_file_url" class="font-medium text-advntm-accent hover:text-advntm-highlight">
											<span v-if="'URL' === pack.type">Open URL &rarr;</span>
											<span v-else>Download</span>
										</a>
									</div>
									<div v-else>
										<a target="_blank" :href="pack.asset_file_url" download class="font-medium text-advntm-accent hover:text-advntm-highlight">
											<span v-if="'URL' === pack.type">Open URL &rarr;</span>
											<span v-else>Download</span>
										</a>
									</div>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
			</div>

			<div v-else>
				<p v-if="true !== get_tab( 'race_pack' ).loading" v-html="get_tab( 'race_pack' ).emptyLabel"></p>
			</div>
		</section>

		<section v-if="active_tab === 'questionnaires'" class="">
			<div class="pb-7">
				<h2 id="questionnaires-title" class="text-lg leading-6 font-medium text-mono-lighter">Questionnaires</h2>
			</div>

			<Loader v-if="true === get_tab( 'questionnaires' ).loading" text="Loading your questionnaires" /> 

			<div v-else> 
				<div v-if="typeof get_tab( 'questionnaires' ).data !== 'undefined' && true === get_tab( 'questionnaires' ).empty">
					<p v-if="true !== get_tab( 'questionnaires' ).loading" v-html="get_tab( 'questionnaires' ).emptyLabel"></p>
				</div>

				<div v-else>
					<Questionnaire class="mb-5 pb-5 border-b border-mono-darker" :data="questionnaire" v-for="questionnaire in Object.values( questionnaires )" :key="questionnaire.id" />
				</div>
			</div>
		</section>

		<section v-if="active_tab === 'withdrawal'" class="">
			<div class="pb-7">
				<h2 id="withdrawal-title" class="text-lg leading-6 font-medium text-mono-lighter">Request a withdrawal</h2>
			</div>

			<div class="withdrawal border-t border-mono-darker pt-5">
				<p class="text-sm font-light text-mono-light mb-6">If you wish to withdraw from your chosen race, you can do so here. Once you withdraw, your request will be manually reviewed by our team and all eligible refund monies will be deposited into your  account. </p>

				<p>View our <a target="_blank" :href="$functions.getSiteData( 'domain', 'main' ) + '/terms-conditions/' + $functions.sanitize( race.name )">Terms &amp; Conditions</a>.</p>

				<div class="withdrawal-action mt-9">
					<Button @click.prevent="this.active_alert = 'withdrawFromRace'" text="Withdraw" />
				</div>

				<Alert :is_open="true === is_active_alert( 'withdrawFromRace' )" id="withdrawFromRace" title="Request a withdrawal" confirm="Withdraw" :description="'Are you sure you want to withdraw from ' + race.name + '?'" v-on:alert_dismissed="alert_dismissed" />
			</div>
		</section>
	</section>
</template>

<script>
	import { PaperClipIcon, SunIcon, GlobeIcon, MapIcon, CalendarIcon, LocationMarkerIcon, FlagIcon, FolderOpenIcon, ExclamationIcon } from '@heroicons/vue/outline';

	export default {
		components: {
			PaperClipIcon,
			CalendarIcon,
			SunIcon,
			GlobeIcon,
			FlagIcon,
			MapIcon,
			LocationMarkerIcon,
			FolderOpenIcon,
			ExclamationIcon
		},

		watch: {
			'active_tab': function( id ) {
				let found_tab = this.get_tab( id );

				if ( null === found_tab ) {
					return false;
				}

				this.get_endpoint( found_tab.endpoint, found_tab.key, found_tab );
			}
		},

		data() {
			return {
				race: false,
				series: false,
				journey: false,
				schedule: false,
				fundraising: false,
				active_alert: false,
				packs: false,
				waiting_list: false,
				status: false,
				is_in_waiting_list: false,
				questionnaires: [],
				active_tab: 'information',
				tabs: [
					{ 
						name: 'Information', 
						id: 'information', 
						waiting_list: true, 
						show_if_status_is_minified: true, 
						hide_if_waiting_list_is_empty: false, 
						empty: false, 
						emptyLabel: false, 
						endpoint: null, 
						loading: false 
					},
					{ name: 'Questionnaires',
						id: 'questionnaires',
						waiting_list: false,
						show_if_status_is_minified: false,
						hide_if_waiting_list_is_empty: false,
						empty: false,
						emptyLabel: "There are no questionnaires to be completed yet.",
						endpoint: [ 'questionnaire/vetting',
						'questionnaire/medical' ],
						key: 'questionnaires',
						loading: false
					},
					{ name: 'Waiting List',
						id: 'waiting_list',
						waiting_list: true,
						show_if_status_is_minified: false,
						hide_if_waiting_list_is_empty: true,
						empty: false,
						emptyLabel: false,
						endpoint: null,
						loading: false
					},
					{ name: 'To do list',
						id: 'to_do_list',
						waiting_list: false,
						show_if_status_is_minified: false,
						hide_if_waiting_list_is_empty: false,
						empty: false,
						emptyLabel: "There are no live to do lists for this race currently.",
						endpoint: 'participant-journey',
						key: 'journey',
						loading: false
					},
					{ name: 'Payments',
						id: 'payments',
						waiting_list: false,
						show_if_status_is_minified: false,
						hide_if_waiting_list_is_empty: false,
						empty: false,
						emptyLabel: "The payment schedule for this race has not yet been confirmed.",
						endpoint: 'payments',
						key: 'schedule',
						loading: false
					},
					{ name: 'Fundraising',
						id: 'fundraising',
						waiting_list: false,
						show_if_status_is_minified: false,
						hide_if_waiting_list_is_empty: false,
						empty: false,
						emptyLabel: "This race doesn't have a fundraising option.",
						endpoint: 'fundraising-page-urls?raceId=' + this.$route.params.id,
						key: 'fundraising',
						loading: false
					},
					{ name: 'Downloads',
						id: 'race_pack',
						waiting_list: false,
						show_if_status_is_minified: false,
						hide_if_waiting_list_is_empty: false,
						empty: false,
						emptyLabel: "No race Downloads are available yet. Check back soon.",
						endpoint: 'race-pack',
						key: 'packs',
						loading: false
					},
					{ name: 'Withdrawal',
						id: 'withdrawal',
						waiting_list: false,
						show_if_status_is_minified: false,
						hide_if_waiting_list_is_empty: false,
						empty: false,
						emptyLabel: false,
						endpoint: false,
						key: 'withdrawal',
						loading: false
					},
				]
			}
		},

		mounted() {
			if ( true === this.$functions.isUserLoggedIn() ) {
				// overrall race information.
				this.axios.get( 'race/' + this.$route.params.id )
				.then( response => {
					if ( 200 === response.status ) {
						let race = response.data.data;

						if (typeof race.has_fundraising_event === 'undefined' || false === race.has_fundraising_event) {
							this.tabs.splice(this.tabs.indexOf(this.tabs.find(tab => tab.key === 'fundraising')), 1);
						}

						// check the status.
						this.axios.get( 'competitor-portal/race/' + this.$route.params.id + '/status' )
						.then( response => {
								if ( "Withdrawn" === response.data ) {
									this.status = 'minified';
								}
							}
						)
						.catch( () => {
							this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem checking your status on this race.' } );

							this.$emit( 'view_loaded', null );
							this.waiting_list = false;
						} );

						this.axios.get( 'competitor-portal/race/' + this.$route.params.id + '/waiting-list' )
						.then( response => {
							if ( 200 === response.status ) {
								// if the waiting list is empty.
								if ( Object.values( response.data.data ).length === 0 ) {
									this.race = race;
									this.$emit( 'view_loaded', true );

									return false;
								}

								if ( typeof response.data.data.find( result => result.id === this.$store.getters.user.id ) !== 'undefined' ) {
									this.waiting_list = response.data.data;
									this.waiting_list.map( ( user, index ) => user.position = index + 1 );

									// find the users position
									let user_position = this.waiting_list.findIndex( result => result.id === this.$store.getters.user.id );

									//  get 2 before, and 2 after
									this.waiting_list = this.waiting_list.slice( user_position - 2, user_position + 3 );

									this.waiting_list_position = response.data.data;
									this.is_in_waiting_list = true;
								}

								this.race = race;

								this.$emit( 'view_loaded', true );
							} else {
								this.waiting_list = false;
							}
						})
						.catch( () => {
							this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem checking your waiting list place on this race.' } );

							this.$emit( 'view_loaded', null );
							this.waiting_list = false;
						} );

						if ( false === this.status ) {
							this.status = 'proceed';
						}
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching this race.' } );
					this.$emit( 'view_loaded', null );
				} );
			}
		},

		methods: {
			tab_is_enabled: function( tab ) {
				if ( typeof this.status === 'undefined' ) {
					return false;
				}

				// if the status is minified, and the tab is set to be shown if status is minified.
				if ( ( this.status === 'minified' && true === tab.show_if_status_is_minified ) ) {
					return true;
				}

				// if the user is in the waiting list, and the tab is set to be shown for waiting list people, but the user is withdrawn, and should only see minified, they should be able to see waiting list stuff, so set to true.
				if ( ( this.status === 'minified' && false === tab.show_if_status_is_minified ) && ( true === this.is_in_waiting_list && true === tab.waiting_list ) ) {
					return true;	
				}

				// if the status is minified, and the tab is set to NOT show if status is minified.
				if ( ( this.status === 'minified' && false === tab.show_if_status_is_minified ) ) {
					return false;
				}

				// if the user is in the waiting list, and the tab is set to be shown for waiting list people.
				if ( ( true === this.is_in_waiting_list && true === tab.waiting_list ) ) {
					return true;	
				}

				// if the waiting list is empty, and the tab is set to be hidden if the waiting list is empty.
				if ( ( Object.values( this.waiting_list ).length === 0 && false === tab.hide_if_waiting_list_is_empty ) ) {
					return true;
				}

				// all other circumstances, proceed false.
				return false;
			},

			get_tab: function( id ) {
				return this.tabs.find( tab => id === tab.id );
			},

			get_endpoint: function( endpoint, key, tab ) {
				tab.empty = false;
				tab.loading = true;

				if ( null === endpoint || false === endpoint ) {
					return false;
				}

				if ( typeof endpoint === 'object' && Object.keys( endpoint ).length > 0 ) {
					Promise.all( 
						endpoint.map( url => this.axios.get( 'competitor-portal/race/' + this.$route.params.id + '/' + url )
							.then( response => {
								return {
									success: true,
									data: response.data,
									url: url
								};
							})
							.catch( () => {
								return { success: false };
							}) 
						)
					).then( ( data ) => {
						data.forEach( prom => {
							if ( true == prom.success ) {
								let url = prom.url;
								let sub_key = url.split('/')[ url.split('/').length - 1 ];

								if ( false === this[ key ][ sub_key ] || typeof this[ key ][ sub_key ] === 'undefined' ) { 
									this[ key ][ sub_key ] = prom.data.data;
									tab.data = prom.data.data;
								}
							}
						} );
					} )
					.then( () => {
						if ( Object.keys( this[ key ] ).length > 0 ) {
							tab.loading = false;
							tab.empty = false;
						}
					} );
				} else {
					if ( false === this[ key ] || typeof this[ key ] === 'undefined' ) { 
						this.axios.get( 'competitor-portal/race/' + this.$route.params.id + '/' + endpoint )
						.then( response => {
							if ( 200 === response.status ) {
								if (typeof response.data.data !== 'undefined') {
									this[ key ] = response.data.data;
								} else {
									this[ key ] = response.data;
								}

								if ('fundraising' === key) {
									this[ key ].fundraising_platform = false;

									if (typeof this[ key ].fundraising_page_login_url !== 'undefined') {
										if (true === this[ key ].fundraising_page_login_url.includes('enthuse')) {
											this[ key ].fundraising_platform = 'enthuse';
										}
									}

									if (typeof this[ key ].fundraising_page_setup_url !== 'undefined') {
										if (true === this[ key ].fundraising_page_setup_url.includes('enthuse')) {
											this[ key ].fundraising_platform = 'enthuse';
										}
									}
								}

								if (Object.values(response.data.data).length <= 0) {
									tab.empty = true;
								}
							
								tab.loading = false;
							}
						} )
						.catch( error => {
							if ( typeof error.response !== 'undefined' && 401 === error.response.status ) {
								this.$functions.handleUnauthorised();
							}

							tab.loading = false;
							tab.empty = true;
						} );
					} else {
						tab.loading = false;
					}
				}
			},

			is_active_alert: function( id ) {
				return id === this.active_alert;
			},

			alert_dismissed: function( id, state ) {
				this.active_alert = false;

				if ( id === 'withdrawFromRace' && 'confirm' === state ) {
					this.withdraw_from_race();
				}
			},

			withdraw_from_race: function() {
				this.axios.post( 
					'competitor-portal/request-withdrawal',
					{
						'userId': this.$store.getters.user.id,
						'raceId': parseInt( this.$route.params.id ),
					}
				)
				.then( response => {
					if ( 200 === response.status ) {
						this.$store.commit( 'message', { type: 200, text: 'Our race team  have been notified of your withdrawal and will review your request shortly.' } );
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem withdrawing you from this race.' } );
				} );
			},

			select_tab: function( event ) {
				let found_tab = this.get_tab( event.srcElement.value );

				if ( null === found_tab ) {
					return false;
				}

				this.active_tab = found_tab.id;
				this.get_endpoint( found_tab.endpoint, found_tab.key, found_tab );
			},

			select_tab_via_button: function( tab ) {
				let found_tab = tab;

				if ( null === found_tab ) {
					return false;
				}

				this.active_tab = tab.id;
				this.get_endpoint( found_tab.endpoint, found_tab.key, found_tab );
			},

			input_checked: function( id ) {
				if ( id ) {
					this.axios.post( 'competitor-portal/race/' + this.$route.params.id + '/complete-journey-step/' + id )
					.then( response => {
						if ( 200 === response.status ) {
							this.journey.steps.map( step => {
								if ( response.data.id !== step.id ) {
									return;
								}

								step.completed_at = response.data.completed_at;
							} );
						}
					} )
					.catch( error => {
						if ( 401 === error.response.status ) {
							this.$functions.handleUnauthorised();
						}
						
						this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem updating your to do list.' } );
					} );
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.redacted {
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		background-color: theme( 'colors.mono.darker' );
		width: 80%;
	}

	.fundraising-platform {
		max-width: 125px;
		height: auto;
		margin-bottom: 2rem;
	}
</style>