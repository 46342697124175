<template>
	<div class="Select max-w-lg w-full" v-if="Object.values( options ).length > 0">
		<div v-if="typeof multiple !== 'undefined' && true === multiple">
			<select :multiple="typeof multiple !== 'undefined' && true === multiple ? true : false" ref="select" @input="handleInput" v-if="'simple' === type" :data-type="type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base text-mono-darkest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent font-light" :class="typeof multiple !== 'undefined' && true === multiple ? 'is_multiple' : false" >
				<option value="">-- Make a selection --</option>

				<option :selected="value.indexOf(option) >= 0" v-for="( option, index ) in options" :key="index">
					{{option}}
				</option>
			</select>

			<select :multiple="typeof multiple !== 'undefined' && true === multiple ? true : false" ref="select" @input="handleInput" v-if="'multidimensional' === type" :data-type="type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base text-mono-darkest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent font-light" :class="typeof multiple !== 'undefined' && true === multiple ? 'is_multiple' : false" >
				<option value="">-- Make a selection --</option>

				<option :value="option.value" :selected="value.indexOf(option.value) >= 0" v-for="( option, index ) in options" :key="index">
					{{ option.name }}
				</option>
			</select>

			<div class="text-sm mt-5 text-mono-lightest">
				Hold CTRL <span class="text-xs">(PC)</span>, or CMD <span class="text-xs">(Mac)</span> and click to select multiple values.
			</div>
		</div>

		<div v-else>
			<select ref="select" @input="handleInput" v-if="'simple' === type" :data-type="type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base text-mono-darkest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent font-light">
				<option value="">-- Make a selection --</option>

				<option :selected="value === option" v-for="( option, index ) in options" :key="index">
					{{option}}
				</option>
			</select>

			<select :multiple="typeof multiple !== 'undefined' && true === multiple ? true : false" ref="select" @input="handleInput" v-if="'multidimensional' === type" :data-type="type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base text-mono-darkest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent font-light">
				<option value="">-- Make a selection --</option>

				<option :value="option.value" :selected="value === option.value" v-for="( option, index ) in options" :key="index">
					{{ option.name }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Select',

		emits: [ 'update:modelValue' ],

		props: {
			options: { 
				type: Array,
				default: function() {
					return [];
				}
			},

			multiple: {
				type: Boolean,
				default: false
			},

			value: {
				type: [String, Array],
				default: ''
			}
		},

		data() {
			return {
				type: 'simple',
				selected: []
			}
		},

		mounted() {
			if ( this.options ) {
				Object.values( this.options ).forEach( option => {
					if ( typeof option === 'string' ) {
						return;
					}

					if ( typeof option === 'object' ) {
						this.type = 'multidimensional';
					}
				} );
			}
		},

		methods: {
			handleInput: function() {
				if ( "" !== this.$refs.select.value ) {
					if (this.multiple !== 'undefined' && true === this.multiple) {
						this.$refs.select.querySelectorAll('option').forEach(option => {
							if (option.value === "") {
								return;
							}

							if (true === option.selected) {
								if (this.selected.indexOf(option.value) < 0) {
									this.selected.push(option.value);
								}
							} else {
								if (this.selected.indexOf(option.value) >= 0) {
									this.selected.splice(this.selected.indexOf(option.value), 1);
								}
							}
						});

						this.$emit('update:modelValue', this.selected );
					} else {
						this.$emit('update:modelValue', this.$refs.select.value );
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	select {
		height: 60px;

		option {
			color: #000;
		}
	}

	select.is_multiple {
		height: 145px;

		* {
			padding-block: .33rem;
			font-weight: theme( 'fontWeight.light' );
		}

		> * + * {
			margin-bottom: 0;
			margin-top: 0.2rem;
		}
	}
</style>