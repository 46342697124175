<template>
	<section v-if="false !== races">
		<section v-if="null !== races && Object.values( races ).length > 0" class="races">
			<ul role="list" class="divide-y divide-mono-darker mb-n4">
				<li class="mb-4" v-for="race in races" :key="race.id">
					<Event v-if="endpoint === 'volunteer-portal/events'" :race="race" />
					<Race v-else :race="race" />
				</li>
			</ul>
		</section>

		<section v-else>
			<p class="text-state-info">
				<InformationCircleIcon class="h-8 w-8 text-mono-white mb-4" aria-hidden="true" />

				<span>It looks like you haven't got any upcoming races.</span>
			</p>
		</section>
	</section>
</template>

<script>
	import sortBy from 'lodash/sortBy';
	import { InformationCircleIcon } from '@heroicons/vue/outline';
	
	export default {
		props: {
			search_term: {
				type: String,
				default: ''
			}
		},

		components: {
			InformationCircleIcon
		},

		data() {
			return {
				races: false,
				all_races: null,
				endpoint: false
			}
		},

		mounted() {
			if ( true === this.$functions.isUserLoggedIn() ) {
				this.endpoint = 'competitor-portal/races';

				if (true === this.$store.getters.is_volunteer) {
					this.endpoint = 'volunteer-portal/events';
				}	

				this.axios.get( this.endpoint )
				.then( response => {
					if ( 200 === response.status ) {
						if ('object' === typeof response.data.events && Object.values(response.data.events).length > 0) {
							this.races = response.data.events;
							this.all_races = response.data.events;
						} else {
							if ( Object.values( response.data.data ).length > 0 ) {
								this.races = response.data.data.filter( race => this.$functions.days( race.starts_at ) > 0 ? race : false );
								this.all_races = response.data.data.filter( race => this.$functions.days( race.starts_at ) > 0 ? race : false );
							}

							if ( Object.values( response.data.data ).length <= 0 ) {
								this.races = null;
							}
						}

						this.races = sortBy(this.races, 'starts_at', 'asc');
					}

					this.$emit( 'view_loaded', true );
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}

					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching your races.' } );
					this.$emit( 'view_loaded', null );
				} );
			}
		},

		watch: {
			search_term: function () {
				if ( ( null !== this.search_term && '' !== this.search_term ) && this.search_term.length >= 2 ) {
					this.races = this.all_races.filter( race => race.name.toLowerCase().includes( this.search_term.toLowerCase() ) );
				} else {
					this.races = this.all_races;
				}	
			}
		}
	}
</script>

<style scoped lang="scss">
	a:not(.race-button):hover { 
		color: theme( 'colors.advntm.highlight' );
	}
</style>