<template>
	<Disclosure as="nav" id="masthead" class="bg-transparent mb-16 sticky z-10 top-0 w-full" v-slot="{ open }">
		<div class="px-8 py-4">
			<div class="flex justify-between align-middle h-16">
				<div class="flex align-middle">
					<div class="flex-shrink-0 flex items-center pr-9">
						<router-link :to="'/'" class="font-bold text-mono-lightest">
							<inline-svg class="w-36 ml-auto mr-auto fill-current" :src="require( '@/' + $functions.getResourceUrl( $functions.getSiteData( 'logo', 'src' ) ) )" />
						</router-link>
					</div>
					
					<Navigation />
				</div>
				<div class="hidden sm:ml-6 sm:flex sm:items-center">
					<Menu as="div" class="ml-3 relative">
						<MenuButton class="leading-none h-auto max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-advntm-accent">
							<span class="sr-only">Open user menu</span>
							<img class="h-8 w-8 rounded-full" :src="user.profile_photo_url" alt="" />
						</MenuButton>

						<UserNavigation />
					</Menu>	
				</div>
				<div class="-mr-2 flex items-center sm:hidden">
					<!-- Mobile menu button -->
					<DisclosureButton class="inline-flex items-center justify-center p-0 w-8 h-8 rounded-md text-gray-400 hover:text-mono-mid hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-advntm-accent">
						<span class="sr-only">Open main menu</span>
						<MenuIcon v-if="!open" class="block h-8 w-8" aria-hidden="true" />
						<XIcon v-else class="block h-8 w-8" aria-hidden="true" />
					</DisclosureButton>
				</div>
			</div>
		</div>

		<DisclosurePanel class="sm:hidden">
			<div class="pt-2 pb-3 space-y-1">
				<router-link v-for="item in navigation" :key="item.name" :to="item.path" :class="[item.current ? 'bg-indigo-50 border-advntm-accent text-indigo-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-mono-dark hover:text-gray-800', 'block px-8 py-2 border-l-4 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">
					{{ item.name }}
				</router-link>

				<button @click="logout" class="block px-8 border-l-4 border-transparent text-base py-2 font-medium leading-normal h-auto text-state-danger" type="button">Logout &rarr;</button>
			</div>
			<div class="pt-4 pb-3 border-t border-mono-dark">
				<div class="flex items-center px-8">
					<div class="flex-shrink-0">
						<img class="h-10 w-10 rounded-full" :src="user.profile_photo_url" alt="" />
					</div>
					<div class="ml-3">
						<div class="text-base font-medium text-gray-800">{{ user.name }}</div>
						<div class="text-sm font-medium text-mono-mid">{{ user.email }}</div>
					</div>
				</div>
			</div>
		</DisclosurePanel>
	</Disclosure>
</template>

<script>
	import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton } from '@headlessui/vue'
	import { MenuIcon, XIcon } from '@heroicons/vue/outline';

	export default {
		name: 'Header', 
		components: {
			Disclosure,
			DisclosureButton,
			DisclosurePanel,
			Menu,
			MenuButton,
			MenuIcon,
			XIcon,
		},

		data() {
			return {
				user: false,
				navigation: []
			}
		},

		mounted() {
			if ( Object.values( this.$router.getRoutes() ).length > 0 ) {
				this.navigation = [];
				this.navigation = this.$router.getRoutes().filter( route => true === route.meta.nav || true === route.meta.userNav );

				if (true === this.$store.getters.is_volunteer) {
					this.navigation = this.navigation.filter(route => (true === route.meta.is_volunteer || null === route.meta.is_volunteer));
				}
			}

			if ( true === this.$functions.isUserLoggedIn() ) {
				this.user = this.$store.getters.user;
			}

			// masthead
			let masthead = document.getElementById( 'masthead' );
			let main = document.querySelector( 'main.app-wrapper' );

			window.addEventListener( 'scroll', () => {
				let main_distance = main.getBoundingClientRect().top;
				let distance = main_distance - masthead.clientHeight;

				if ( distance <= 0 ) {
					masthead.classList.add( 'bg-mono-darkest' );
				} else {
					masthead.classList.remove( 'bg-mono-darkest' );
				}
			} );
		},

		methods: {
			logout: function() {
				if ( confirm( 'Are you sure you want to logout?' ) ) {
					this.$store.commit( 'user_update', [] );
					this.$store.commit( 'advntm_site', null );
					this.$store.commit( 'token', false );
					this.$store.commit( 'message', { type: 200, text: 'You have successfully been logged out.' } );

					setTimeout( () => {
						this.$router.push( '/login' );
					}, 1000 )
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	a:hover { 
		text-decoration: none;
	}
</style>