<template>
	<form @submit.prevent="update" v-if="null !== user" class="space-y-8 divide-t divide-mono-darker ">
		<div v-if="Object.values( empty_fields ).length > 0">
			<Notification v-if="Object.values( empty_fields ).length === 1" 
				:message="'There is <strong>' + Object.values( empty_fields ).length + '</strong> required field to complete on your profile'" />
			<Notification v-else 
				:message="'There are <strong>' + Object.values( empty_fields ).length + '</strong> required fields to complete on your profile'" />
		</div>

		<div class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
			<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
				<div class="mb-9">
					<h3 class="text-lg font-medium text-mono-lightest">Personal Information</h3>
					<p class="mt-5 text-sm text-mono-mid">Remember to keep your ADVNTM personal information up to date, so we can contact you when neccessary.</p>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							First name <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input readonly required :class="empty_fields.indexOf('first_name') >= 0 ? 'is-invalid' : null" type="text" v-model="user.first_name" id="first-name" autocomplete="given-name" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" />
							<p class="mt-4 text-xs text-mono-mid">To request a change to your First Name, contact <a :href="'mailto:' + accounts_email">{{accounts_email}}</a>.</p>
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Last name <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input readonly required :class="empty_fields.indexOf('last_name') >= 0 ? 'is-invalid' : null" type="text" v-model="user.last_name" id="last-name" autocomplete="family-name" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" />
							<p class="mt-4 text-xs text-mono-mid">To request a change to your Last Name, contact <a :href="'mailto:' + accounts_email">{{accounts_email}}</a>.</p>
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Email address <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input readonly required :class="empty_fields.indexOf('email') >= 0 ? 'is-invalid' : null" id="email" v-model="user.email" type="email" autocomplete="email" class="block max-w-lg w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent border-mono-lightest" />
							<p class="mt-4 text-xs text-mono-mid">To request a change to your Email, contact <a :href="'mailto:' + accounts_email">{{accounts_email}}</a>.</p>
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Phone number <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('race_phone_number') >= 0 ? 'is-invalid' : null" id="tel" v-model="user.race_phone_number" type="tel" autocomplete="tel" class="block max-w-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent border-mono-lightest" />
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Date of Birth <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('date_of_birth') >= 0 ? 'is-invalid' : null" id="date_of_birth" v-model="user.date_of_birth" type="date" autocomplete="date_of_birth" class="block max-w-lg w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent border-mono-lightest" />
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="nationality" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Nationality <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :class="empty_fields.indexOf('nationality') >= 0 ? 'is-invalid' : null" label="nationality" :options="countries" v-model="user.nationality" :value="user.nationality" />
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="gender" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Gender <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :class="empty_fields.indexOf('gender') >= 0 ? 'is-invalid' : null" label="gender" :options="genders" v-model="user.gender" :value="user.gender" />
						</div>
					</div>
				</div>
			</div>
		</div>

	<!-- 	<div v-if="( 'undefined' === typeof $store.getters.is_volunteer || $store.getters.is_volunteer === false)" class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
			<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
				<div class="mb-9">
					<h3 class="text-lg font-medium text-mono-lightest">Address</h3>
					<p class="mt-5 text-sm text-mono-mid">Edit your address.</p>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="primary_language" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Primary language <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :class="empty_fields.indexOf('primary_language') >= 0 ? 'is-invalid' : null" label="primary_language" :options="languages" v-model="user.primary_language" :value="user.primary_language" />
						</div>
					</div>
				</div>
				
			</div>
		</div> -->

		<div v-if="( 'undefined' !== typeof $store.getters.is_volunteer && $store.getters.is_volunteer === true)" class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
			<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
				<div class="mb-9">
					<h3 class="text-lg font-medium text-mono-lightest">Languages</h3>
					<p class="mt-5 text-sm text-mono-mid">Edit your languages.</p>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="primary_language" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Primary language <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :class="empty_fields.indexOf('primary_language') >= 0 ? 'is-invalid' : null" label="primary_language" :options="languages" v-model="user.primary_language" :value="user.primary_language" />
						</div>
					</div>
				</div>
				<div v-if="( null !== user.primary_language && 'Other' === user.primary_language )" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
					<label for="emergency_contact_number" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
						Primary language cont. <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
					</label>
					<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
						<input required :class="empty_fields.indexOf('primary_language_other') >= 0 ? 'is-invalid' : null" type="text" v-model="user.primary_language_other" id="emergency_contact_number" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" />
					</div>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="primary_language" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Secondary language <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :multiple="true" :class="empty_fields.indexOf('secondary_language') >= 0 ? 'is-invalid' : null" label="secondary_language" :options="languages" v-model="user.secondary_language" :value="user.secondary_language" />
						</div>
					</div>
				</div>
				<div v-if="( null !== user.secondary_language && 'Other' === user.secondary_language )" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
					<label for="emergency_contact_number" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
						Secondary language cont. <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
					</label>
					<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
						<input required :class="empty_fields.indexOf('secondary_language_other') >= 0 ? 'is-invalid' : null" type="text" v-model="user.secondary_language_other" id="emergency_contact_number" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" />
					</div>
				</div>
			</div>
		</div>

		<div class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
			<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
				<div class="mb-9">
					<h3 class="text-lg font-medium text-mono-lightest">Dietary requirements</h3>
					<p class="mt-5 text-sm text-mono-mid">Let us know if you have any specific dietary requirements.</p>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="emergency_contact_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Dietary preferences <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :class="empty_fields.indexOf('dietary_preferences') >= 0 ? 'is-invalid' : null" label="dietary_preferences" :options="dietary_options" v-model="user.dietary_preferences" :value="user.dietary_preferences" />
						</div>
					</div>

					<div v-if="( null !== user.dietary_preferences && 'Other' === user.dietary_preferences )" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="emergency_contact_number" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Dietary preferences cont. <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('dietary_preferences_other') >= 0 ? 'is-invalid' : null" type="text" v-model="user.dietary_preferences_other" id="emergency_contact_number" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" />
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="emergency_contact_number" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Allergies <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('allergies') >= 0 ? 'is-invalid' : null" type="text" v-model="user.allergies" id="emergency_contact_number" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" autocomplete="allergies" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
			<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
				<div class="mb-9">
					<h3 class="text-lg font-medium text-mono-lightest">Emergency Contact</h3>
					<p class="mt-5 text-sm text-mono-mid">Let us know who to contact in case of an emergency.</p>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="emergency_contact_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Name <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('emergency_contact_name') >= 0 ? 'is-invalid' : null" type="text" v-model="user.emergency_contact_name" id="emergency_contact_name" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" autocomplete="emergency_contact_name" />
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="emergency_contact_number" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Number <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('emergency_contact_number') >= 0 ? 'is-invalid' : null" type="text" v-model="user.emergency_contact_number" id="emergency_contact_number" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" autocomplete="emergency_contact_number" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
			<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
				<div class="mb-9">
					<h3 class="text-lg font-medium text-mono-lightest">Medical Contact</h3>
					<p class="mt-5 text-sm text-mono-mid">Let us know a medical professional to contact in case of an emergency.</p>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="doctor_contact_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Name <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('doctor_contact_name') >= 0 ? 'is-invalid' : null" type="text" v-model="user.doctor_contact_name" id="doctor_contact_name" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" autocomplete="doctor_contact_name" />
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="doctor_contact_number" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Number <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<input required :class="empty_fields.indexOf('doctor_contact_number') >= 0 ? 'is-invalid' : null" type="text" v-model="user.doctor_contact_number" id="doctor_contact_number" class="max-w-lg block w-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mono-darkest focus:ring-advntm-accent sm:max-w-xs border-mono-lightest" autocomplete="doctor_contact_number" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="space-y-8 divide-y divide-mono-darker sm:space-y-5">
			<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
				<div class="mb-9">
					<h3 class="text-lg font-medium text-mono-lightest">Miscellaneous</h3>
					<p class="mt-5 text-sm text-mono-mid">Additional information we may require from time to time.</p>
				</div>
				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="doctor_contact_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							T-shirt size <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :class="empty_fields.indexOf('t_shirt_size') >= 0 ? 'is-invalid' : null" label="t_shirt_size" :options="t_shirt_sizes" v-model="user.t_shirt_size" :value="user.t_shirt_size" />
						</div>
					</div>
				</div>

				<div class="space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-mono-darker sm:pt-5">
						<label for="doctor_contact_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							T-shirt style fit <span class="-ml-0.5 text-state-danger">* <span class="hidden sr-only" aria-label="Required field">Required field</span></span>
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-2 text-mono-darker">
							<Select :class="empty_fields.indexOf('t_shirt_style_of_fit') >= 0 ? 'is-invalid' : null" label="t_shirt_style_of_fit" :options="t_shirt_fits" v-model="user.t_shirt_style_of_fit" :value="user.t_shirt_style_of_fit" />
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<div class="pt-5">
			<div class="flex justify-between align-middle">
				<div></div>
				
				<Button class="w-36" id="saveProfile" text="Save" />
			</div>
		</div>
	</form>
</template>

<script>
	import countries from '@/assets/json/countries.json';
	import genders from '@/assets/json/genders.json';
	import dietary_options from '@/assets/json/dietary_options.json';
	import languages from '@/assets/json/languages.json';
	import t_shirt_sizes from '@/assets/json/t_shirt_sizes.json';
	import t_shirt_fits from '@/assets/json/t_shirt_fits.json';

	export default {
		data() {
			return {
				user: null,
				empty_fields: new Array(),
				countries: countries,
				genders: genders,
				dietary_options: dietary_options,
				languages: languages,
				t_shirt_sizes: t_shirt_sizes,
				t_shirt_fits: t_shirt_fits
			}
		},

		mounted() {
			// grab the countries and sort alpha3 to value.
			if ( countries ) {
				countries.map( country => country.value = country.alpha3 );
			}

			if ( true === this.$functions.isUserLoggedIn() ) {
				this.axios.get( 'competitor-portal/user' )
				.then( response => {
					if ( 200 === response.status ) {
						this.user = response.data.data;

						delete this.user.t_shirt_size;

						Object.values( this.user ).filter( ( field, index ) => ( null === field || "" === field ) && Object.keys( this.user )[index].indexOf('_other') < 0 ? this.empty_fields.push(Object.keys(this.user)[ index ]) : null );
						this.$emit( 'view_loaded', true );
						this.user.date_of_birth = this.$functions.formatDateAlt( this.user.date_of_birth );
					}
				} )
				.catch( error => {
					console.log(error);
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}
					
					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem fetching your profile.' } );
					this.$emit( 'view_loaded', null );
				} );
			}
		},

		methods: {
			update: function( ) {
				this.$store.commit( 'message', [] );

				this.axios.put( 'competitor-portal/user', this.user )
				.then( response => {
					if ( 200 === response.status ) {
						this.$store.commit( 'message', { type: 200, text: 'Your profile was successfully updated.' } );
					}
				} )
				.catch( error => {
					if ( 401 === error.response.status ) {
						this.$functions.handleUnauthorised();
					}
					
					this.$store.commit( 'message', { type: 401, text: 'Sorry, there has been a problem updating your profile.' } );
				} );
			}
		}
	}
</script>